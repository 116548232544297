import React from 'react';
import Navigation from '../components/Navigation';
import {Helmet} from "react-helmet";


const Abrupt = () => {
    return (
        <div className='abruptContent'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Accueil</title>
                <meta name="description" content="ÉCRITURE - PRODUCTION - DIRECTION ARTISTIQUE - TOURNAGE - RÉALISATION - POST PRODUCTION" />
                <link rel="canonical" href="https://abruptprod.com/abrupt" />
            </Helmet>
            <Navigation/>
            <div className='abrupt'>
                <p>
                    Abrupt est une <span className='green'>société de production de films et de photos.</span> <br/><br/>
                    Nous produisons et réalisons des films en maîtrisant chaque étape de la chaîne de création, de l'écriture jusqu'à la phase de post production : <br/><br/>
                    <span>écriture - production - direction artistique - tournage - réalisation - post production</span><br/><br/>
                    abrupt est très fier de représenter de jeunes réalisateurs / photographes et fédérer des équipes 
                    de passionnés autour de chaque conception de films.<br/><br/>
                    elle intègre une équipe de réalisateurs interne, permettant une créativité centralisée, une grande 
                    flexibilité ainsi qu'une optimisation des coûts et des délais.
                </p>
            </div>
        </div>
    );
};

export default Abrupt;
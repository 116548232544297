import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Alinea = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='allAlinea'>
            <div className='alineaContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/1034463052" controls/></div>
                <div className='credits'>
                    <h1>COLLECTION AUTOMNE <br/>2024</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Marie Vivière - Alexandre Meunier<br/>
                        <span>DOP</span> David Capsir<br/>
                        <span>Ass cam</span> Sébastien Roche<br/>
                        <span>Electrician</span> Némo del Aguila<br/>
                        <span>Color Grading</span> Alexandre Meunier<br/>
                        <span>Music mix and mastering</span>  Florian Adrien<br/>
                        <span>Equipment rental</span> Indie Location 
                    </p>
                </div>
                
            </div>

            <div className='alineaContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/1036308149/96755c8fc9" controls/></div>
                <div className='credits'>
                    <h1>COLLECTION ÉTÉ 2024</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Marie Vivière - Alexandre Meunier<br/>
                        <span>DOP</span> David Capsir<br/>
                        <span>Ass cam</span> Sébastien Roche<br/>
                        <span>Electrician</span> Marouane Chajar
                    
                    </p>
                </div>
                
            </div>
            <div className='alineaContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/779307425" controls/></div>
                <div className='credits'>
                    <h1>Collection été 2022</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Loic Foulon<br/>
                        <span>Producers</span> Marie Vivière - Alexandre Meunier<br/>
                        <span>DOP</span> Julien Jauffret<br/>
                        <span>Electrician</span> Arthur Cardenas<br/>
                        <span>Editor</span> Aurélien Baud<br/>
                        <span>Color Grading</span>  Julien Jauffret<br/>
                        <span>Music mix and mastering</span> Antoine Duchene 
                    </p>
                </div>
            </div>

            <div className='alineaContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/776310375" controls/></div>
                <div className='credits'>
                    <h1>COLLECTION PRINTEMPS 2022</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Loic Foulon<br/>
                        <span>Ass. director</span> Alexandre Meunier<br/>
                        <span>Producers</span> Marie Vivière <br/>
                        <span>DOP</span> Jean Combier<br/>
                        <span>Ass cam</span> Maxime Kieken<br/>
                        <span>Electrician</span> Charles Sayer<br/>
                        <span>Color</span> Grading Jean Combier<br/>
                        <span>Music mix and mastering</span> Antoine Duchene <br/>
                        <span>Voice Over</span> Seth Young Ruiz<br/>
                        <span>Equipment rental</span> Indie Location / Pix Location <br/>
                        <span>Cast</span> Cédric Bieler<br/>
                    </p>
                </div>
            </div>

            <div className='alineaContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/776302403" controls/></div>
                <div className='credits'>
                    <h1>COLLECTION AUTOMNE 2022</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Aurélien baud<br/>
                        <span>Producers</span> Marie Vivière - Alexandre Meunier<br/>
                        <span>DOP</span> Julien Jauffret<br/>
                        <span>Electrician</span> Charlène Dumons<br/>
                        <span>Make-up artist</span> Laurette Darnaud<br/>
                        <span>Editor & Color</span> Grading Aurélien Baud<br/>
                        <span>Music mix and mastering</span> Antoine Duchene <br/>
                        <span>BTS</span> Robin Durget<br/><br/>
                        <span>Cast</span> Elodie Senigallia - Maxence Victor
                    </p>
                </div>
            </div>

            <div className='alineaContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/778910110" controls/></div>
                <div className='credits'>
                    <h1>COLLAB MANUFACTURE DIGOIN</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> Aurélien Baud
                    </p>
                </div>
            </div>
            <div className='alineaContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/779328770" controls/></div>
                <div className='credits'>
                    <h1>COLLECTION NOEL 2022</h1>
                    <br/>
                    <p>
                        <span>Production</span> Abrupt <br/>
                        <span>Director</span> David Capsir<br/>
                        <span>Producers</span> Marie Vivière - Alexandre Meunier<br/>
                        <span>DOP</span> Julien Jauffret<br/>
                        <span>Ass Cam</span> Julien Ruphy<br/>
                        <span>Electrician</span> Rémi Santonacci<br/>
                        <span>Make-up artist</span> Sandra Hernandez<br/>
                        <span>Editor & Color Grading</span> David Capsir<br/>
                        <span>Music mix and mastering</span> Antoine Duchene <br/>

                        <span>Cast</span> Numa - David Capsir - Malina Loana-Ferrante
                    </p>
                </div>
            </div> 
            </div>
        </div>
    );
};

export default Alinea;